import '@github/braintree-encryption'

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Submit credit card details to Braintree for public-key encryption.
// POST encrypted data to our servers to limit PCI exposure.
//
// NOTE: This does *not* improve security in any significant way.
on('submit', '.js-braintree-encrypt', function ({currentTarget}) {
  const braintreeKey = currentTarget.getAttribute('data-braintree-key')
  if (!braintreeKey) throw new Error('Missing `data-braintree-key` attribute on element')

  const braintree = window.Braintree.create(braintreeKey)
  braintree.encryptForm(currentTarget)
})
