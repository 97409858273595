// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

// Toggle credit card form to enter in a new cc number.
on('click', '.js-enter-new-card', function (e) {
  // Fallback assumes only one card at a time is supported and allows buttons outside
  // the existing card context to trigger UI for adding a new card.
  const cardInfo = (e.currentTarget.closest<HTMLElement>('.js-zuora-existing-card') ||
    document.querySelector<HTMLElement>('.js-zuora-existing-card'))!
  const billingSection = document.querySelector<HTMLElement>('.js-billing-section')!
  const dataCollectionEnabled = document.querySelector<HTMLElement>('.js-enter-new-card')!
  /* eslint-disable-next-line github/no-d-none */
  cardInfo.classList.add('d-none')
  billingSection.hidden = false
  const iframe = billingSection.querySelector<HTMLElement>('iframe')!
  iframe.setAttribute('width', '100%')
  const height = dataCollectionEnabled.getAttribute('data-sdn-data-collection') === 'true' ? '237' : '677'
  iframe.setAttribute('height', height)
})

// Toggle edit card for Zuora saved credit card info
on('click', '.js-edit-card-details', function (e) {
  const container = document.getElementById('zuora_payment')!
  const cardInfo = e.currentTarget.closest<HTMLElement>('.js-zuora-billing-info')!
  const billingSection = document.querySelector<HTMLElement>('.js-billing-section')!

  /* eslint-disable-next-line github/no-d-none */
  container.classList.toggle('d-none')
  /* eslint-disable-next-line github/no-d-none */
  cardInfo.classList.toggle('d-none')
  billingSection.classList.remove('PaymentMethod--creditcard-added')
  billingSection.classList.add('PaymentMethod--creditcard')
})

const vatCountries: {[key: string]: string} = {
  Australia: 'AU00 000 000 000',
  Austria: 'ATU000000000',
  Belgium: 'BE0000000000',
  Bulgaria: 'BG000000000',
  Croatia: 'HR00000000000',
  Cyprus: 'CY000000000X',
  'Czech Republic': 'CZ00000000...',
  Denmark: 'DK00 00 00 00',
  Estonia: 'EE000000000',
  Finland: 'FI00000000',
  France: 'FRXX 000000000',
  Germany: 'DE000000000',
  Greece: 'EL000000000',
  Hungary: 'HU00000000',
  Iceland: 'IS...',
  India: 'IN00000000000',
  Ireland: 'IE0000000XA',
  Italy: 'IT00000000000',
  Japan: '000000000000',
  Latvia: 'LV00000000000',
  Lithuania: 'LT000000000...',
  Luxembourg: 'LU00000000',
  Malta: 'MT00000000',
  Netherlands: 'NL000000000B00',
  'New Zealand': 'NZ0000000000000',
  Norway: 'NO000000000MVA',
  Poland: 'PL0000000000',
  Portugal: 'PT000000000',
  Romania: 'RO0000000000',
  Russia: 'RU0000000000',
  Slovakia: 'SK0000000000',
  Slovenia: 'SI00000000',
  Spain: 'ES...',
  Sweden: 'SE000000000000',
  Switzerland: 'CHE000000000TVA',
  Taiwan: '00000000',
  'United Kingdom': 'GB000000000',
}

const noPostalCodes = [
  'Angola',
  'Antigua and Barbuda',
  'Aruba',
  'Bahamas',
  'Belize',
  'Benin',
  'Botswana',
  'Cameroon',
  'Comoros',
  'Congo (Brazzaville)',
  'Congo (Kinshasa)',
  'Cook Islands',
  "Côte d'Ivoire",
  'Djibouti',
  'Dominica',
  'Fiji',
  'French Southern Lands',
  'Ghana',
  'Guyana',
  'Hong Kong',
  'Ireland',
  'Kiribati',
  'Korea, North',
  'Malawi',
  'Maritania',
  'Mauritius',
  'Montserrat',
  'Nauru',
  'Niue',
  'Qatar',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Sao Tome and Principe',
  'Seychelles',
  'Sierra Leone',
  'Sint Maarten (Dutch part)',
  'Solomon Islands',
  'Somalia',
  'Suriname',
  'Syria',
  'Togo',
  'Tokelau',
  'Tonga',
  'United Arab Emirates',
  'Vanuatu',
  'Yemen',
  'Zimbabwe',
]

// eslint-disable-next-line i18n-text/no-en
const USA = 'United States of America'
const CANADA = 'Canada'

//Determine whether VAT is applicable, show VAT field and appropriate help text.
function setupCountryFields(countrySelect: HTMLSelectElement) {
  let country
  const selectedCountry = countrySelect.options[countrySelect.selectedIndex]
  if (selectedCountry) {
    country = selectedCountry.textContent
  }

  const vatFormat = country ? vatCountries[country] : null
  const container = countrySelect.closest<HTMLElement>('.js-setup-creditcard')!
  container.classList.toggle('is-vat-country', vatFormat != null)

  const vatLabelEl = container.querySelector<HTMLElement>('.js-vat-help-text')!
  vatLabelEl.textContent = vatFormat ? `(${vatFormat})` : ''

  const stateSelect = container.querySelector<HTMLSelectElement>('.js-select-state')!
  container.classList.toggle('is-international', country !== USA && country !== CANADA)
  stateSelect.required = country === USA
  if (country !== USA) stateSelect.value = ''

  if (country === CANADA) {
    const selectProvinceEl = container.querySelector<HTMLSelectElement>('.js-select-province')!
    const provinceLabel = container.querySelector<HTMLElement>('.js-region-label')!
    provinceLabel.textContent = 'Province'
    selectProvinceEl.disabled = false
    selectProvinceEl.required = true
    /* eslint-disable-next-line github/no-d-none */
    stateSelect.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    selectProvinceEl.classList.remove('d-none')
  } else {
    const selectProvinceEl = container.querySelector<HTMLSelectElement>('.js-select-province')!
    const provinceLabel = container.querySelector<HTMLElement>('.js-region-label')!
    provinceLabel.textContent = 'State'
    selectProvinceEl.disabled = true
    selectProvinceEl.required = false
    selectProvinceEl.value = ''
    /* eslint-disable-next-line github/no-d-none */
    stateSelect.classList.remove('d-none')
    /* eslint-disable-next-line github/no-d-none */
    selectProvinceEl.classList.add('d-none')
  }

  const postalCode = container.querySelector<HTMLInputElement>('.js-postal-code-field')!
  const noPostalCode = noPostalCodes.indexOf(country || '') >= 0
  container.classList.toggle('no-postcodes', noPostalCode)
  postalCode.required = !noPostalCode
  if (noPostalCode) postalCode.value = ''
}

observe('.js-select-country', {
  constructor: HTMLSelectElement,
  initialize(countrySelect) {
    setupCountryFields(countrySelect)
    if (countrySelect.form) fire(countrySelect.form, 'change')
    countrySelect.addEventListener('change', function () {
      setupCountryFields(countrySelect)
    })
  },
})
