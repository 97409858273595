import {dispatchThrottledInputEvent} from '../throttled-input'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {softNavigate} from '@github-ui/soft-navigate'

observe('input.js-plan-choice:checked', {
  constructor: HTMLInputElement,
  add(input) {
    const freePlanChecked = input.value === 'free'

    for (const el of document.querySelectorAll('.js-plan-choice-label')) {
      el.classList.toggle('open', el === input)
    }

    const label = input.closest('.js-plan-choice-label')

    if (label) {
      const addonField = label.querySelector('.js-addon-purchase-field')
      if (addonField instanceof HTMLInputElement) {
        dispatchThrottledInputEvent(addonField)
      }

      if (input.checked) {
        label.classList.add('open')
      }
    }

    // ghe-cloud-trial flag enabled means the only options for Enterprise are Trial or Server
    // both skip collecting payment
    const businessPlusChecked = input.classList.contains('js-data-ghe-cloud-trial') && input.value === 'business_plus'
    const noPaymentPlanChecked = freePlanChecked || businessPlusChecked

    for (const el of document.querySelectorAll('.js-billing-section')) {
      el.classList.toggle('has-removed-contents', noPaymentPlanChecked)
    }

    if (freePlanChecked) {
      const contactUsElement = document.querySelector('.js-contact-us')
      if (contactUsElement != null) {
        /* eslint-disable-next-line github/no-d-none */
        contactUsElement.classList.add('d-none')
      }

      const purchaseButton = document.querySelector('.js-purchase-button')
      if (purchaseButton != null) {
        purchaseButton.removeAttribute('disabled')
      }
    }

    // hide "create organization" button if enterprise server is selected
    const enterpriseServerPlanChecked =
      document.querySelector('input.js-enterprise-choice[value=server]:checked') && input.value === 'business_plus'

    if (enterpriseServerPlanChecked === true) {
      const signupContinueActions = document.querySelector('.SignUpContinueActions')
      if (signupContinueActions != null) {
        signupContinueActions.classList.add('has-removed-contents')
      }
    }
  },
})

on('click', '.js-duration-change', async function ({currentTarget}) {
  const plan = document.querySelector('.js-upgrade-plan-change:checked')
  const transformInput = document.querySelector('.js-transform-user')

  let url = `?plan_duration=${(currentTarget as HTMLInputElement).getAttribute('data-duration')}`
  if (transformInput) url = `${url}&transform_user=1`
  if (plan instanceof HTMLInputElement) url = `${url}&new_plan=${plan.value}`

  await stashBillingInfo()
  softNavigate(url)
})

on('change', 'input.js-upgrade-plan-change', function ({currentTarget}) {
  const transformInput = document.querySelector('.js-transform-user')

  let url = `?new_plan=${(currentTarget as HTMLInputElement).value}`
  if (transformInput) url = `${url}&transform_user=1`

  softNavigate(url)
})

on('change', 'input.js-org-duration-change', async function ({currentTarget}) {
  const params = new URL(document.location.href, window.location.origin).searchParams
  if (document.querySelectorAll('.js-experiment-seats').length > 0) {
    params.set('plan_duration', (currentTarget as HTMLInputElement).value)
  } else {
    const plan = document.querySelector<HTMLInputElement>('.js-upgrade-org-plan-change:checked')!
    const account = document.querySelector<HTMLInputElement>('.js-account-name')!
    params.set('plan', plan.value)
    params.set('plan_duration', (currentTarget as HTMLInputElement).value)
    params.set('org', account.value)
  }
  await stashBillingInfo()
  const url = `?${params.toString()}`

  softNavigate(url)
})

async function softNavigateWithPlanDuration(duration: string) {
  const params = new URL(document.location.href, window.location.origin).searchParams
  params.set('plan_duration', duration)

  await stashBillingInfo()
  const url = `?${params.toString()}`
  softNavigate(url)
}

on('change', 'input.js-bus-duration-change', function ({currentTarget}) {
  softNavigateWithPlanDuration((currentTarget as HTMLInputElement).value)
})

on('click', '.js-bus-year-duration', function () {
  softNavigateWithPlanDuration('year')
})

on('click', '.js-bus-month-duration', function () {
  softNavigateWithPlanDuration('month')
})

on('change', 'input.js-upgrade-org-plan-change', function ({currentTarget}) {
  const account = document.querySelector<HTMLInputElement>('.js-account-name')!

  const params = new URL(document.location.href, window.location.origin).searchParams
  params.set('plan', (currentTarget as HTMLInputElement).value)
  params.set('org', account.value)

  const url = `?${params.toString()}`
  softNavigate(url)
})

export const stashBillingInfo = async (): Promise<void> => {
  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection')
  if (formContainer) {
    const billingInfo = {
      first_name: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_first_name')?.value,
      last_name: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_last_name')?.value,
      entity_name: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_entity_name')?.value,
      address1: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_address1')?.value,
      address2: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_address2')?.value,
      city: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_city')?.value,
      vat_code: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_vat_code')?.value,
      country_code: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_country_code')?.value,
      region: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_region')?.value,
      postal_code: formContainer?.querySelector<HTMLInputElement>('#account_screening_profile_postal_code')?.value,
    }

    const formData = new FormData()
    for (const [key, value] of Object.entries(billingInfo)) {
      if (value !== undefined) {
        formData.append(`account_screening_profile[${key}]`, value)
      }
    }

    if (!Array.from(formData).length) {
      return
    }

    const targetTypeElem = document.querySelector<HTMLInputElement>('#account_screening_profile_target')
    if (!targetTypeElem) {
      return
    }

    const targetType = targetTypeElem.value
    let targetId
    let targetName = ''

    if (targetType === 'organization') {
      targetId = document.querySelector<HTMLInputElement>('#account_screening_profile_organization_id')?.value
      targetName = 'organization_id'
    } else if (targetType === 'business') {
      targetId = document.querySelector<HTMLInputElement>('#account_screening_profile_business_id')?.value
      targetName = 'business_id'
    }

    formData.set('target', targetType)
    if (targetId) {
      formData.set(targetName, targetId)
    }

    try {
      const csrfToken = document.querySelector('.js-stash-billing-info-csrf')!.getAttribute('value')!
      const request = new Request('/account/stash_billing_information', {
        method: 'POST',
        headers: {
          'Scoped-CSRF-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: formData,
      })
      await self.fetch(request)
    } catch {
      /* noop */
      /* we don't want to block the user from changing the duration if the request fails */
    }
  }
}
