// Logic to support the coupon redemption flow
//
// .js-plan-row       - The row that gets selected when a plan is chosen
// .js-choose-plan    - The button in the plan row that triggers actions
// .js-account-row    - The row that gets selected when an account is chosen
// .js-choose-account - The button in the account row that triggers actions
//

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {ready} from '@github-ui/document-ready'

on('submit', '.js-find-coupon-form', function (e) {
  const form = e.target
  if (!(form instanceof HTMLFormElement)) return
  const action = form.action
  const couponCodeField = document.querySelector<HTMLInputElement>('#code')!
  window.location.href = `${action}/${encodeURIComponent(couponCodeField.value)}`
  e.preventDefault()
})

on('click', '.js-choose-account', function (e) {
  for (const element of document.querySelectorAll('.js-plan-row, .js-choose-plan')) {
    element.classList.remove('selected')
  }

  const planInput = document.querySelector<HTMLInputElement>('.js-plan')!
  planInput.value = ''

  for (const billingSection of document.querySelectorAll('.js-billing-section')) {
    billingSection.classList.add('has-removed-contents')
  }

  const account = e.currentTarget.closest('.js-account-row')
  if (account) {
    selectAccount(account)
  }
})

on('click', '.js-billing-settings-redeem-coupon-btn', function ({currentTarget}) {
  const formWrapper = document.querySelector<HTMLElement>('.js-billing-settings-redeem-coupon-form-wrapper')!
  formWrapper.hidden = false
  const target = currentTarget as HTMLElement
  target.hidden = true
  const couponCodeField = document.querySelector<HTMLInputElement>('input[name="code"]')!
  couponCodeField.focus()
})

on('click', '.js-billing-settings-cancel-redeem-coupon-btn', function ({currentTarget}) {
  const formWrapper = currentTarget.closest<HTMLElement>('.js-billing-settings-redeem-coupon-form-wrapper')!
  formWrapper.hidden = true
  const redeemButton = document.querySelector<HTMLElement>('.js-billing-settings-redeem-coupon-btn')!
  redeemButton.hidden = false
})

on('click', '.js-choose-plan', function (e) {
  if (!(e.target instanceof Element)) return
  const plan = e.target.closest<HTMLElement>('.js-plan-row')!
  selectPlan(plan)
})

observe('.js-choose-plan-radio:checked', {
  add(el) {
    selectPlan(el.closest<HTMLElement>('.js-plan-row')!)
  },
})

observe('.js-plan-row.selected', {
  add: enableNextButton,
})

observe('.js-choose-account.selected', {
  add: enableNextButton,
})

function enableNextButton(el: Element) {
  const form = el.closest('form')
  if (!form) return
  const redeemButton = form.querySelector('.js-redeem-button')
  if (!(redeemButton instanceof HTMLButtonElement)) return
  redeemButton.disabled = el.classList.contains('free-plan')
}

function selectAccount(accountRow: Element) {
  const accountLogin = accountRow.getAttribute('data-login')
  const accountPlan = accountRow.getAttribute('data-plan')
  if (!accountLogin || !accountPlan) return

  for (const element of document.querySelectorAll('.js-account-row, .js-choose-account')) {
    element.classList.remove('selected')
  }

  accountRow.classList.add('selected')
  const chooseAccountButton = accountRow.querySelector('.js-choose-account')
  if (chooseAccountButton) chooseAccountButton.classList.add('selected')

  const accountInput = document.querySelector<HTMLInputElement>('.js-account')!
  if (accountInput) accountInput.value = accountLogin

  const planSection = document.querySelector<HTMLElement>('.js-plan-section')
  if (planSection) planSection.hidden = false

  for (const plan of document.querySelectorAll<HTMLElement>('.js-billing-plans')) {
    plan.hidden = true
  }

  const planTable = document.querySelector<HTMLElement>(`.js-billing-plans[data-login='${accountLogin}']`)
  if (!planTable) return

  planTable.hidden = false
  const planRows = planTable.querySelectorAll('.js-plan-row')
  const planRow = planRows.length === 1 ? planRows[0] : planTable.querySelector(`[data-name='${accountPlan}']`)
  if (planRow instanceof Element) selectPlan(planRow)
}

function selectPlanForDataCollection(wrapperElement: Element) {
  const accountLogin = wrapperElement.getAttribute('data-login')
  const accountPlan = wrapperElement.getAttribute('data-plan')
  if (!accountLogin || !accountPlan) return

  for (const plan of document.querySelectorAll<HTMLElement>('.js-billing-plans')) {
    plan.hidden = true
  }

  const planTable = document.querySelector<HTMLElement>(`.js-billing-plans[data-login='${accountLogin}']`)
  if (!planTable) return

  planTable.hidden = false
  const planRows = planTable.querySelectorAll('.js-plan-row')
  const planRow = planRows.length === 1 ? planRows[0] : planTable.querySelector(`[data-name='${accountPlan}']`)
  if (planRow instanceof Element) selectPlan(planRow)
}

function selectPlan(planRow: Element) {
  const planName = planRow.getAttribute('data-name')
  const planTable = planRow.closest('.js-billing-plans')
  if (!planTable) return
  const accountLogin = planTable.getAttribute('data-login')
  if (!accountLogin) return

  const hasBilling = planTable.getAttribute('data-has-billing') === 'true'
  const cost = parseInt(planRow.getAttribute('data-cost') || '', 10)

  for (const row of document.querySelectorAll('.js-plan-row, .js-choose-plan')) {
    row.classList.remove('selected')
  }

  planRow.classList.add('selected')

  const chooseButton = planRow.querySelector('.js-choose-plan')
  if (chooseButton) chooseButton.classList.add('selected')

  const radio = planRow.querySelector('.js-choose-plan-radio')
  if (radio instanceof HTMLInputElement) radio.checked = true

  const planInput = document.querySelector<HTMLInputElement>('.js-plan')!
  if (planInput) planInput.value = planName || ''

  const dataCollectionWrapperElement = document.querySelector('.js-coupons-data-collection-wrapper')

  if (!dataCollectionWrapperElement) {
    if (cost === 0 || hasBilling) {
      const billingSection = document.querySelector('.js-billing-section')
      if (billingSection) billingSection.classList.add('has-removed-contents')
    } else if (accountLogin) {
      const accountBillingSection = document.querySelector(`.js-billing-section[data-login='${accountLogin}']`)
      if (accountBillingSection) accountBillingSection.classList.remove('has-removed-contents')
    }
  }
}

;(async function () {
  await ready
  const account = document.querySelector('.js-account-row.selected')
  if (account) selectAccount(account)

  const dataCollectionWrapperElement = document.querySelector('.js-coupons-data-collection-wrapper')
  if (dataCollectionWrapperElement) {
    selectPlanForDataCollection(dataCollectionWrapperElement)
  } else {
    const plan = document.querySelector('.js-plan-row.selected')
    if (plan instanceof Element) selectPlan(plan)
  }
})()
