// Pay with PayPal button wireup.
//
// Currently supporting the iFrame approach which will be supplanted by a mini
// browser at some point in the future.

import type {MerchantConfiguration} from '@github/braintree-encryption'
import braintree from '@github/braintree-encryption'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

const paypalStates = ['paypal-loading', 'paypal-logged-in', 'paypal-logged-out', 'paypal-down']

function braintreeSetup(token: string, type: string, options: MerchantConfiguration): Promise<void> {
  const {onSuccess} = options
  return new Promise(resolve =>
    braintree.setup(
      token,
      type,
      Object.assign({}, options, {
        onSuccess: () => {
          onSuccess()
          resolve()
        },
      }),
    ),
  )
}

function setPaypalState(container: Element, state: string) {
  container.classList.remove(...paypalStates)
  if (paypalStates.indexOf(state) >= 0) {
    container.classList.add(state)
  }
}

async function getClientToken(paymentMethods: Element): Promise<string> {
  const token = paymentMethods.getAttribute('data-token')
  if (token) return token
  const response = await fetch('/account/billing/client_token', {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (!response.ok) {
    throw new Error('Response is not OK')
  }
  return await response.text()
}

async function setupBraintreePaypal(container: Element) {
  const paymentMethods = container.closest<HTMLElement>('.js-payment-methods')!
  if (paymentMethods.classList.contains('paypal-loading')) {
    return
  }

  if (paymentMethods.classList.contains('paypal-logged-in')) {
    return
  }
  container.textContent = ''
  setPaypalState(paymentMethods, 'paypal-loading')

  try {
    const token = await getClientToken(paymentMethods)

    if (!paymentMethods.hasAttribute('data-token')) {
      paymentMethods.setAttribute('data-token', token)
    }

    const selector = container.getAttribute('data-nonce-field')!
    const nonceField = paymentMethods.querySelector<HTMLElement>(selector)!

    await braintreeSetup(token, 'paypal', {
      container,
      displayName: 'GitHub',
      paymentMethodNonceInputField: nonceField,
      enableCORS: true,
      onSuccess() {
        setPaypalState(paymentMethods, 'paypal-logged-in')

        const awaitingPayment = document.querySelector<HTMLElement>('.js-awaiting-payment')
        if (awaitingPayment) {
          if (
            awaitingPayment.getAttribute('data-toggle-plan-upgrade-visibility') === 'true' &&
            awaitingPayment.hidden === true
          ) {
            awaitingPayment.hidden = false
          }
        }
      },
      onReady() {
        setPaypalState(paymentMethods, 'paypal-logged-out')
      },
    })

    const cancelButton = document.querySelector<HTMLElement>('#bt-pp-cancel')!
    cancelButton.setAttribute('type', 'button')
    const icon = document.querySelector('.js-paypal-cancel-icon')!.cloneNode(true)
    if (icon instanceof Element) {
      /* eslint-disable-next-line github/no-d-none */
      icon.classList.remove('d-none')
    }
    cancelButton.appendChild(icon)
  } catch (error) {
    setPaypalState(paymentMethods, 'paypal-down')
    throw error
  }
}

// Handle cancel button clicks
on('click', '#bt-pp-cancel', function (event) {
  const paymentMethods = event.currentTarget.closest<HTMLElement>('.js-payment-methods')!
  setPaypalState(paymentMethods, 'paypal-logged-out')
  const container = event.currentTarget.closest<HTMLElement>('.js-paypal-container')!
  setupBraintreePaypal(container)
})

// Setup and render the paypal button if active.
observe('.js-paypal-container', {
  add(el) {
    if (el.closest('.active') != null) {
      setupBraintreePaypal(el)
    }
  },
})
